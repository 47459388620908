<template>
  <el-dialog 
    :visible.sync="modalVisible" 
    append-to-body
    title="Первичное оборудование/ЛЭП" 
    top="2rem" width="80%" 
    class="select_primary_equipment--modal" 
    :close-on-click-modal="false"
  >
    <div class="my-flex-container-column">
      <select-primary-equipment-filter
        @active-change="filterActive = $event"
        :filter-model="filterModel"
        :with-line="!filterModel.hideLines"
        @clear-filters="clearFilters" />
      <div style="text-align: center">
        <pagination
          style="text-align: center"
          :total-count="totalCount"
          :page-size.sync="pageSize"
          :current-page.sync="pageNumber"
          @size-change="handleSizeChange"
          @current-change="handleCurrentChange"
        />
      </div>
      <div>
        <el-button @click="toggleAllNodes" type="text" class="ml-2">Свернуть/развернуть всё</el-button>
        <span class="total_items">Всего: {{this.primaryEquipmentsCount}}</span>
      </div>
      <div v-loading="loading" class="my-flex-data-content">
        <infinite-tree 
          ref="tree"
          :data="primaryEquipments"
          :style="{ height: '100%'}"
          @on-check-click="handleCheck"
          @on-open-node="applyChecking"
        >
          <div class="infinite-tree-node" slot-scope="{ node }">{{ node.color }}</div>
        </infinite-tree>
      </div>
      <div class="dialog-footer">
        <el-button type="primary" @click="accept">Применить</el-button>
        <el-button type="default" @click="cancel">Отменить</el-button>
      </div>
    </div>

  </el-dialog>
</template>

<script>
import { mapGetters } from 'vuex';
import cloneDeep from 'lodash.clonedeep';
import pagination from '@/components/Ui/Pagination';
import primaryEquipmentsApi from '@/api/eventsAndIncidents/primaryEquipments';
// import primaryEquipmentsApi from '@/api/nsi/primaryEquipments';
import InfiniteTree from '@/components/Ui/InfiniteTree/InfiniteTree';
import filterHelper from '@/mixins/filterHelper';
import SelectPrimaryEquipmentFilter from '@/views/Global/PrimaryEquipments/PrimaryEquipmentsFilter.vue';

export default {
  name: 'SelectPrimaryEquipmentModal',
  components: {
    InfiniteTree,
    pagination,
    SelectPrimaryEquipmentFilter
  },
  mixins: [filterHelper],
  props: {
    primaryEquipmentModalVisible: { type: Boolean, default: () => false },
  },
  watch: {
    filterModel: {
      handler() {
        this.getPrimaryEquipments();
      },
      deep: true
    },
  },
  data() {
    return {
      pageNumber: 1,
      pageSize: 10,
      totalCount: 0,
      sortField: 'name',
      sortDesc: false,
      loading: false,
      filter: ['1'],
      filterModel: {
        nameFilter: '',
        energyObjectIdFilter: [],
        duOrganizationName: '',
        dvOrganizationName: '',
        baseVoltageFilter: '',
        primaryEquipmentTypeIdFilter: [],
        eoRegionFilter: [],
        skipEoObject: false,
        isLocalNsiFilter: false,
        isRuFilter: false,
        techAdministrationOrganizationsFilter: [],
        techManagementOrganizationsFilter: [],
        hideLines: false
      },
      primaryEquipments: [],
      selectedPrimaryEquipment: {},
      primaryEquipmentsCount: 0,
    };
  },
  computed: {
     ...mapGetters('identity', ['user']),
    ...mapGetters('dictionaries', ['baseVoltages', 'primaryEquipmentTypesWithLine', 'operationalZones']),
    modalVisible: {
      get() {
        return this.primaryEquipmentModalVisible;
      },
      set(val) {
        if (!val) {
          this.cancel();
        }
      },
    },
    tableSizeConstant() {
      if (!this.filterActive) {
        return 140;
      }

      return 120;
    },
    treeContainer() {
      return this.$refs.tree;
    }
  },
  async created() {
    await this.getPrimaryEquipments();
  },
  methods: {
    async getPrimaryEquipments() {
      this.loading = true;
      const params = {
        pageNumber: this.pageNumber,
        pageSize: this.pageSize,
        nameFilter: this.filterModel.nameFilter,
        energyObjectIdFilter: this.filterModel.energyObjectIdFilter,
        duOrganizationName: this.filterModel.duOrganizationName,
        dvOrganizationName: this.filterModel.dvOrganizationName,
        baseVoltageFilter: this.filterModel.baseVoltageFilter,
        primaryEquipmentTypeIdFilter: this.filterModel.primaryEquipmentTypeIdFilter,
        skipEoObject: this.filterModel.skipEoObject,
        isLocalNsiFilter: this.filterModel.isLocalNsiFilter,
        isRuFilter: this.filterModel.isRuFilter,
        hideLines: this.filterModel.hideLines,
        sortField: this.sortField,
        sortDesc: this.sortDesc,
        ownerOrganizationsFilter: this.filterModel.ownerOrganizationsFilter,
        eoRegionFilter: this.filterModel.eoRegionFilter,
        techAdministrationOrganizationsFilter: this.filterModel.techAdministrationOrganizationsFilter,
        techManagementOrganizationsFilter: this.filterModel.techManagementOrganizationsFilter
      };
      const res = await primaryEquipmentsApi.getPrimaryEquipments(params);
      if (res.data) {
        this.primaryEquipments = res.data.items;
        this.totalCount = res.data.totalCount;
        this.primaryEquipmentsCount = res.data.treeItemsCount;
        this.treeContainer.tree.loadData(this.primaryEquipments);

        this.applyChecking();
      }
      this.loading = false;
    },
    applyChecking() {
      const { nodes } = this.treeContainer.tree;

      nodes.forEach((node) => {
        if (node.nodeType === 'primaryEquipment') {
          if (this.selectedPrimaryEquipment.primaryEquipmentId === node.primaryEquipmentId) {
            node.state.checked = true;
          } else {
            node.state.checked = false;
          }

          node.сheckAllowed = true;
          node.viewAllowed = false;
        }
      });

      this.treeContainer.rerender();
    },
    handleCheck(node) {
      if (node.state.checked) {
        const oldPe = cloneDeep(this.selectedPrimaryEquipment);
        this.selectedPrimaryEquipment = cloneDeep(node);
        this.selectedPrimaryEquipment.parent = null;

        const nodes = this.treeContainer.tree.nodes.filter((t) => t.nodeType === 'primaryEquipment');
        if (nodes && nodes.length > 0) {
          nodes.forEach((n) => {
            if (n.primaryEquipmentId === node.primaryEquipmentId) {
              n.state.checked = true;
            }

            if (n.primaryEquipmentId === oldPe.primaryEquipmentId) {
              n.state.checked = false;
            }
          });

          this.treeContainer.rerender();
        }
      }
    },
    async handleSizeChange() {
      this.pageNumber = 1;
      await this.getPrimaryEquipments();
    },
    async handleCurrentChange() {
      await this.getPrimaryEquipments();
    },

    async handleChangeFilter(val) {
      const objectLength = Object.keys(val);
      if (objectLength.length > 0) {
        this.filterActive = true;
      } else {
        this.filterActive = false;
      }
    },

    cancel() {
      this.$emit('cancel');
    },
    accept() {
      this.$emit('accept', this.selectedPrimaryEquipment);
    },
    toggleAllNodes() {
      this.$refs.tree.toggleNodes();
      this.applyChecking();
    },
    clearFilters() {
      this.filterModel = {
        nameFilter: '',
        energyObjectIdFilter: [],
        duOrganizationName: '',
        dvOrganizationName: '',
        baseVoltageFilter: '',
        primaryEquipmentTypeIdFilter: [],
        eoRegionFilter: [],
        skipEoObject: false,
        isLocalNsiFilter: false,
        isRuFilter: false,
        techAdministrationOrganizationsFilter: [],
        techManagementOrganizationsFilter: [],
        hideLines: false
      };
    },
  }
};
</script>

<style scoped lang="scss">
  .el-dialog__wrapper.el-dialog.el-dialog__body {
    padding: 20px;
  }
  .el-radio-group {
    font-size: initial !important;
  }

  .el-radio-group {
    width: 100%;
  }

  span.el-radio__label {
    padding-left: 5px;
  }

    span.el-radio__label > span {
      padding: 0px 5px;
    }

  span.el-radio__inner {
    margin: 0px 0px 9px 0px;
  }

  .el-tabs__header.is-top {
    margin: 0px !important;
  }

  button.el-button.el-button--text:first-child {
    margin-left: 0px;
  }

  .el-col > span {
    font-size: 12px;
  }
  .tree-item > span {
    font-size: 14px;
  }
  /*button.el-button.el-button--text:hover {
    color: #3078bf;
  }*/
  .el-select {
    width: 100%;
  }

  span {
    // white-space: nowrap;
    vertical-align: middle;
  }

  .dialog-footer {
    text-align: center;
  }

  .tree-container {
    overflow-y: auto;
    max-height: 40vh;
    min-height: 40vh;
    overflow-x: hidden;
  }

  .tree-item > span > i {
    margin: 0px 5px 0px 0px;
  }

  .event-add--tree {
    font-size: 12px;
    .node__content

  {
    height: auto;
  }

  .custom-tree-node {
    .tree-item > span

  {
    white-space: normal;
  }

  }
  }
.total_items {
   float: right;
   color: #909399;
   font-family: Montserrat-Regular;
   font-size: 14px;
   margin-top: 12px;
   margin-right: 8px;
}
</style>
