<template>
  <div style="padding: 0 10px;
    overflow: auto;
    height: 85vh;">
    <div slot="title">
      <el-button class="back_action_panel--first_element custom-button " :disabled="loading"
                 @click="handleSave()" type="text">
        <SaveIcon/>
        Сохранить
      </el-button>
      <el-button class="custom-button " @click="handleCancel()" type="text">
        <CancelIcon/>
        Отменить
      </el-button>
    </div>
    <el-form :rules="rules" v-loading="loading" ref="eventCardForm" :model="eventCard"
             label-width="16rem">
      <el-row :gutter="20" class="mt-0">
        <el-col :span="12">
          <el-row :gutter="15" class="mt-1">
            <el-col :span="10" class="mt-2">
              <el-form-item label="Номер события" prop="number">
                <el-input size="small" disabled v-model="eventCard.number"></el-input>
              </el-form-item>
            </el-col>
            <el-col :span="14" class="mt-2">
              <el-form-item label="Дата и время события" prop="eventTime">
                <el-date-picker type="datetime"
                                v-mask="'##.##.#### ##:##'"
                                v-model="eventCard.eventTime"
                                size="small"
                                placeholder="дд.мм.гггг чч:мм"
                                format="dd.MM.yyyy HH:mm"
                                style="width:100%;"
                                @change="changeEventTime"
                                :picker-options="datePickerOptions">
                </el-date-picker>
              </el-form-item>
            </el-col>

            <el-col v-if="userHasRoleGroup('ExternalUser')" :span="24">
              <el-form-item label="Требует корректировки:" prop="needCorrect">
                <el-checkbox v-model="eventCard.needCorrect"></el-checkbox>
              </el-form-item>
            </el-col>

          </el-row>

          <el-row v-if="!this.userHasRoleGroup('ExternalUser')" :gutter="15" class="mt-0">
            <el-col :span="10">
              <el-form-item label="Номер аварии" prop="incidentNumber">
                <router-link
                  :to="{ name: 'IncidentItem', params: { id: this.$route.params.incidentId} }">
                  <el-link size="small" type="primary">{{ this.$route.params.incidentNumber }}
                  </el-link>
                </router-link>
              </el-form-item>
            </el-col>
          </el-row>

          <el-row :gutter="10" class="mt-0">
            <el-col :span="24">
              <el-form-item label="Первичное оборудование/ЛЭП" prop="primaryEq">
                <el-col :span="22" style="padding:0px; width:93%;">
                  <el-input size="small" disabled v-model="primaryEquipmentName"></el-input>
                </el-col>
                <el-col :span="2" style="padding-right: 0px; text-align: right; width: 7%;">
                  <el-button size="small" icon="el-icon-edit"
                             @click="showSelectPrimaryEquipmentModal" circle></el-button>
                </el-col>
              </el-form-item>
            </el-col>
          </el-row>

          <el-row :gutter="10" class="mt-0">
            <el-col :span="24">
              <el-form-item prop="panel">
                <template slot="label">
                  <span class="mr-2"> Устройство РЗА</span>
                  <el-tooltip placement="bottom" effect="light">
                    <!-- <div slot="content">Указанный раздел предназначен исключительно
                      <br/> для заведения в аварию информации по сработавшему
                      <br/> общестанционному/общеподстанционному оборудованию,
                      <br/> а также для случаев, когда срабатывание устройства РЗА
                      <br/> не связано с повреждением и/или аварийным
                      <br/> отключением (неотключением)
                      <br/> ЛЭП/первичного оборудования энергообъекта
                    </div>-->
                    <div slot="content">Раздел предназначен для учета срабатываний
                      <br/> общестанционного/общеподстанционного оборудования, а также
                      <br/> для срабатываний устройств РЗА не связанных
                      <br/> с повреждением и/или аварийным отключением (неотключением)
                      <br/> ЛЭП/первичного оборудования энергообъекта
                    </div>
                    <i class="el-icon-info tooltip"/>
                  </el-tooltip>
                </template>
                <el-col :span="22" style="padding:0px; width:93%;">
                  <el-input size="small" disabled v-model="secondEquipmentPanelName"></el-input>
                </el-col>
                <el-col :span="2" style="padding-right: 0px; text-align: right; width: 7%;">
                  <el-button size="small" icon="el-icon-edit"
                             @click="showSelectSecondEquipmentPanelModal" circle></el-button>
                </el-col>
              </el-form-item>
            </el-col>
          </el-row>

          <el-row :gutter="10" class="mt-0">
            <el-col :span="24">
              <el-form-item label="Энергообъект" prop="energyObjectName">
                <el-input size="small" disabled v-model="energyObjectName"></el-input>
              </el-form-item>
            </el-col>
          </el-row>

          <el-row :gutter="10" class="mt-0">
            <el-col :span="24">
              <el-form-item label="Напряжение" prop="baseVoltageName">
                <el-input size="small" v-if="baseVoltageName" disabled
                          v-model="baseVoltageName"></el-input>
                <el-input size="small" v-if="!baseVoltageName" disabled
                          value="Не указано"></el-input>
              </el-form-item>
            </el-col>
          </el-row>

          <el-row :gutter="10" class="mt-0">
            <el-col :span="24">
              <el-form-item label="Заводское устройство РЗА" prop="productAssetModelName">
                <el-input size="small" v-if="productAssetModelName" disabled
                          v-model="productAssetModelName"></el-input>
                <el-input size="small" v-if="!productAssetModelName" disabled
                          value="Не указано"></el-input>
              </el-form-item>
            </el-col>
          </el-row>
          <el-row :gutter="10" class="mt-0" v-if="showEventMapMsg">
            <el-col :span="24">
              <el-card>
                <el-row class="my-label-14">
                  <el-col :span="6" style="padding: 12px 0">Сопоставление событий</el-col>
                  <el-col :span="6">
                    <el-button type="text" icon="el-icon-refresh" @click="loadMap"></el-button>
                  </el-col>
                </el-row>
                <el-row class="my-label-14">
                  <el-col :span="6" style="padding: 12px 0">Найдено события для сопоставления:
                  </el-col>
                  <el-col :span="6">
                    <el-button type="text" @click="handleToMap">{{ eventMap.length }}</el-button>
                  </el-col>
                </el-row>
              </el-card>
            </el-col>
          </el-row>
          <!--          <el-row :gutter="10" class="mt-0">
                      <el-col :span="24">
                        <el-form-item label="Описание события" prop="description">
                          <el-input size="small" v-model="eventCard.description" type="textarea" :rows="15" placeholder=""></el-input>
                        </el-form-item>
                      </el-col>
                    </el-row>-->
        </el-col>
        <el-col :span="12">
          <el-row :gutter="10" class="mt-1">
            <el-col :span="24" class="mt-2">
              <el-form-item prop="eventTypeId" label="Тип события:">
                <el-select popper-class="search-select" size="small"
                           v-model="eventCard.eventTypeId" placeholder=""
                >
                  <el-option v-for="item in eventTypes"
                             :key="item.id"
                             :label="item.name"
                             :value="item.id">
                  </el-option>
                </el-select>
              </el-form-item>
            </el-col>
          </el-row>
          <el-row :gutter="10" class="mt-0">
            <el-col :span="24">
              <el-form-item prop="shortCircuitForm" label="Вид КЗ:">
                <el-select popper-class="search-select" size="small"
                           v-model="eventCard.shortCircuitFormId" placeholder=""
                           @change="onChangeFaultedPhases">
                  <el-option v-for="item in shortCircuitForms"
                             :key="item.id"
                             :label="item.code + ' (' + item.name + ')'"
                             :value="item.id">
                  </el-option>
                </el-select>
              </el-form-item>
            </el-col>
          </el-row>
          <el-row :gutter="10" class="mt-0">
            <el-col :span="24">
              <el-form-item label="Место КЗ:">
                <el-select popper-class="search-select" size="small"
                           v-model="eventCard.shortCircuitPlaceId" placeholder="">
                  <el-option v-for="item in shortCircuitPlaces.filter(t => t.actual)"
                             :key="item.id"
                             :label="item.code + ' (' + item.name + ')'"
                             :value="item.id">
                  </el-option>
                </el-select>
              </el-form-item>
            </el-col>
          </el-row>
          <el-row :gutter="10" class="mt-0">
            <el-col :span="24">
              <el-form-item label="Поврежденные фазы:">
                <el-select popper-class="search-select" size="small"
                           v-model="eventCard.faultedPhases" multiple placeholder=""
                           :multiple-limit="faultedPhasesLimit">
                  <el-option v-for="item in faultedPhases"
                             :key="item.id"
                             :label="item.name"
                             :value="item.id">
                  </el-option>
                </el-select>
              </el-form-item>
            </el-col>
          </el-row>
          <el-row :gutter="10" class="mt-0">
            <el-col :span="24">
              <el-form-item label="Доп. информация:">
                <el-select multiple v-model="eventCard.cardInformation" size="large">
                  <el-option
                    v-for="item in cardInformations"
                    :key="item.id"
                    :label="item.name"
                    :value="item.id"
                    :disabled="item.isDisabled"
                  />
                </el-select>
              </el-form-item>
            </el-col>
          </el-row>

          <el-row :gutter="10" class="mt-0">
            <el-col :span="24">
              <el-form-item label="Описание события" prop="description">
                <el-input size="small" v-model="eventCard.description" type="textarea" :rows="15"
                          placeholder=""></el-input>
              </el-form-item>
            </el-col>
          </el-row>
        </el-col>
      </el-row>
    </el-form>


    <primary-equipment-modal
      v-if="primaryEquipmentModalVisible"
      :primary-equipment-modal-visible="primaryEquipmentModalVisible"
      @accept="acceptPe($event)"
      @cancel="cancelPe"/>

    <second-equipment-panel-modal
      v-if="secondEquipmentPanelModalVisible"
      :second-equipment-panel-modal-visible="secondEquipmentPanelModalVisible"
      @accept="acceptSep($event)"
      @cancel="cancelSep"/>

    <event-mapping-modal :my-store="this.myModalStore"></event-mapping-modal>

  </div>
</template>

<script>
import cloneDeep from 'lodash.clonedeep';
import { mapGetters } from 'vuex';
import formatters from '@/mixins/formatters.js';
import dateHelpers from '@/mixins/dateHelpers';
import eventsApi from '@/api/eventsAndIncidents/events/';
import udEventsApi from '@/api/eventsAndIncidents/userDataEvents/';
import SaveIcon from '@/assets/icons/save.svg';
import CancelIcon from '@/assets/icons/cancel.svg';
import EventMappingModalStore from '@/views/EventsAndIncidents/EventMapping/EventMappingModalStore';
import EventMappingModal from '@/views/EventsAndIncidents/EventMapping/EventMappingModal.vue';
import SecondEquipmentPanelModal from './Modals/SelectSecondEquipmentPanelModal';
import PrimaryEquipmentModal from './Modals/SelectPrimaryEquipmentModal';


export default {
  name: 'EventAddForm',
  mixins: [formatters, dateHelpers],
  components: {
    EventMappingModal,
    SaveIcon,
    CancelIcon,
    SecondEquipmentPanelModal,
    PrimaryEquipmentModal
  },
  data() {
    const eventTimeValidator = (rule, value, callback) => {
      const minDate = new Date(2012, 0);
      const currentDate = new Date();
      if (value !== null && value < minDate) {
        return callback(new Error('Дата не должна быть старше 01.01.2012'));
      } else if (value > currentDate) {
        return callback(new Error('Дата не должна быть больше текущей'));
      } else if (this.eventCard.eventTime == null) {
        return callback(new Error('Поле обязательно для заполнения'));
      } else {
        return callback();
      }
    };
    const eventValidator = (rule, value, callback) => {
      if (this.eventCard.secondEquipmentPanelId == null && this.eventCard.primaryEquipmentId == null) {
        return callback(new Error('Необходимо выбрать один объект, на который создается событие'));
      } else if (this.eventCard.secondEquipmentPanelId !== null && this.eventCard.primaryEquipmentId !== null) {
        return callback(new Error('Должен быть выбран только один объект, на который создается событие'));
      } else {
        return callback();
      }
    };
    return {
      loading: false,

      eventCard: {},
      baseVoltageName: '',
      energyObjectName: '',
      productAssetModelName: '',
      primaryEquipmentName: '',
      secondEquipmentPanelName: '',

      selectedPrimaryEquipment: {},
      selectedSecondEquipmentPanel: {},

      eventDictionaries: {},

      primaryEquipmentModalVisible: false,
      secondEquipmentPanelModalVisible: false,
      eventMap: [],
      rules: {
        eventTime: [
          {
            validator: eventTimeValidator,
            trigger: 'blur',
            required: true
          }
        ],
        primaryEq: [
          {
            validator: eventValidator,
            trigger: 'blur',
            required: true
          }
        ],
        panel: [
          {
            validator: eventValidator,
            trigger: 'blur',
            required: true
          }
        ]
      },
      faultedPhasesLimit: 1
    };
  },
  computed: {
    ...mapGetters('dictionaries', [
      'cardInformations', 'shortCircuitForms', 'shortCircuitPlaces', 'faultedPhases', 'eventTypes', 'estimations', 'staffCategories', 'technicalReasons'
    ]),
    ...mapGetters('identity', ['userHasRoleGroup', 'userHasRole']),
    showEventMapMsg() {
      return this.eventMap && this.eventMap.length > 0;
    },
    showEventsMatch() {
      return this.userHasRole('EventsMatchExternal');
    }
  },
  async created() {
    if (this.userHasRoleGroup('InternalUser')) {
      if (!this.$route?.params?.incidentId) {
        this.$router.back();
      }
    }

    this.myModalStore = new EventMappingModalStore(this.userHasRoleGroup('ExternalUser'), { toUserDate: this.toUserDate, toServerUtcDate: this.toServerUtcDate });

    this.eventCard = {
      number: null,
      eventTime: null,
      primaryEquipmentId: null,
      secondEquipmentPanelId: null,
      description: null,
      incidentId: this.$route.params.incidentId,
      eventFaultedPhases: [],
      needCorrect: true
    };
    // this.eventCard.eventTime = await this.getNow();
    this.eventCard.eventTime = this.$route.params.incidentTime;
  },
  watch: {

    'eventCard.primaryEquipmentId': function () {
      if (this.showEventsMatch) {
        this.loadMap();
      }
    },
    'eventCard.secondEquipmentPanelId': function () {
      if (this.showEventsMatch) {
        this.loadMap();
      }
    },
    // 'eventCard.eventTime': function () {
    //   if (this.showEventsMatch) {
    //     this.loadMap();
    //   }
    // },
    estimations: {
      handler(est) {
        debugger;
        this.myModalStore.setEstimations(est);
      },
      deep: true
    },
    staffCategories: {
      handler(staff) {
        debugger;
        this.myModalStore.setStaffCategories(staff);
      },
      deep: true
    },
    technicalReasons: {
      handler(tr) {
        this.myModalStore.loadTechReasonsAsync(tr);
      },
      deep: true
    },
    // eventCard: {
    //   handler(eventCard) {
    //     this.loadMap(eventCard);
    //   },
    //   deep: true
    // }
  },
  methods: {
    changeEventTime() {
      if (this.showEventsMatch) {
        this.loadMap();
      }
    },
    onChangeFaultedPhases() {
      if (this.eventCard.shortCircuitFormId === 2 || this.eventCard.shortCircuitFormId === 3) {
        this.faultedPhasesLimit = 1;
      } else if (this.eventCard.shortCircuitFormId === 4 || this.eventCard.shortCircuitFormId === 6) {
        this.faultedPhasesLimit = 2;
      } else if (this.eventCard.shortCircuitFormId === 5) {
        this.eventCard.faultedPhases = [];
        this.faultedPhasesLimit = 3;
        this.eventCard.faultedPhases.push(...[1, 2, 3]);
      } else {
        this.faultedPhasesLimit = this.faultedPhases.length;
      }
    },
    async handleToMap() {
      if (this.eventMap && this.eventMap.length > 0) {
        this.myModalStore.state.dialogOpen = true;
      }
    },
    async loadMap() {
      if (this.eventCard && (this.eventCard.primaryEquipmentId || this.eventCard.secondEquipmentPanelId) && this.eventCard.eventTime) {
        debugger;
        try {
          const date = this.toServerUtcDate(this.eventCard.eventTime);
          if (this.userHasRoleGroup('ExternalUser')) {
            this.eventMap = await this.myModalStore.openExtByEventIdAsync(this.eventCard.primaryEquipmentId, date, this.eventCard.secondEquipmentPanelId);
          } else {
            const arrSecEquipPanelId = [];
            if (this.eventCard.secondEquipmentPanelId) {
              arrSecEquipPanelId.push(this.eventCard.secondEquipmentPanelId);
            }
            const arrPriEquipId = [];
            if (this.eventCard.primaryEquipmentId) {
              arrPriEquipId.push(this.eventCard.primaryEquipmentId);
            }
            this.eventMap = await this.myModalStore.openIntByIncidentAsync(arrPriEquipId, date, arrSecEquipPanelId);
          }
        } catch (e) {
          this.$message.error(e.message);
        }
      }
    },
    showSelectPrimaryEquipmentModal() {
      this.primaryEquipmentModalVisible = true;
    },
    showSelectSecondEquipmentPanelModal() {
      this.secondEquipmentPanelModalVisible = true;
    },

    handleCancel() {
      if (this.userHasRoleGroup('ExternalUser')) {
        this.$router.push({ name: 'Events' });
      } else {
        this.$router.push({
          name: 'IncidentItem',
          params: { id: this.$route.params.incidentId }
        });
      }
    },
    async validateForm() {
      const valid = await this.$refs.eventCardForm.validate();
      return valid;
    },
    async handleSave() {
      const valid = await this.validateForm();
      if (!valid) return;
      const res = await this.saveAsync();

      if (res && res.data) {
        if (this.$route.params.incidentId) {
          await this.$router.push({
            name: 'IncidentEventItem',
            params: { id: res.data }
          });
        } else {
          await this.$router.push({
            name: 'EventItem',
            params: { id: res.data }
          });
        }
      }
    },
    async saveAsync() {
      this.loading = true;
      const saveAction = this.userHasRoleGroup('ExternalUser')
        ? udEventsApi.createEvent
        : eventsApi.createEventByIncident;

      try {
        return await saveAction(this.getSaveable());
      } catch (e) {
        this.$message.error(e.message);
      }
      this.loading = false;
      return null;
    },
    getSaveable() {
      const result = cloneDeep(this.eventCard);
      result.eventTime = this.dateSetter(result.eventTime);
      return result;
    },

    cancelPe() {
      this.primaryEquipmentModalVisible = false;
    },
    acceptPe(event) {
      this.eventCard.primaryEquipmentId = event.primaryEquipmentId;
      this.primaryEquipmentName = event.name;
      this.energyObjectName = event.energyObjectsString;
      this.baseVoltageName = event.baseVoltageName;

      this.eventCard.secondEquipmentPanelId = null;
      this.productAssetModelName = null;
      this.secondEquipmentPanelName = null;
      this.primaryEquipmentModalVisible = false;
    },
    cancelSep() {
      this.secondEquipmentPanelModalVisible = false;
    },
    acceptSep(event) {
      this.eventCard.secondEquipmentPanelId = event.id;
      this.secondEquipmentPanelName = event.name;
      this.energyObjectName = event.energyObjectName;
      this.productAssetModelName = event.productAssetModelName;

      this.eventCard.primaryEquipmentId = null;
      this.primaryEquipmentName = null;
      this.baseVoltageName = null;
      this.secondEquipmentPanelModalVisible = false;
    }
  }
};
</script>

<style scoped lang="scss">
.el-dialog__wrapper.el-dialog.el-dialog__body {
  padding: 20px;
}

.el-radio-group {
  font-size: initial !important;
}

.el-radio-group {
  width: 100%;
}

span.el-radio__label {
  padding-left: 5px;
}

span.el-radio__label > span {
  padding: 0px 5px;
}

span.el-radio__inner {
  margin: 0px 0px 9px 0px;
}

.el-tabs__header.is-top {
  margin: 0px !important;
}

button.el-button.el-button--text:first-child {
  margin-left: 0px;
}

.el-col > span {
  font-size: 12px;
}

.el-select {
  width: 100%;
}

span {
  //white-space: nowrap;
  vertical-align: middle;
}

.dialog-footer {
  text-align: center;
}

.scrollableTree {
  width: 100%;
  overflow-y: scroll;
  overflow-x: hidden;
  max-height: 50vh;
}

.tree-item > span > i {
  margin: 0px 5px 0px 0px;
}

.event-add--tree {
  font-size: 12px;

  .node__content {
    height: auto;
  }

  .custom-tree-node {
    .tree-item > span {
      white-space: normal;
    }

  }
}
</style>
